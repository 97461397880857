<template>
  <!-- eslint-disable -->
  <div>
    <add-edit :is-add.sync="isAdd" ref="cateAdd"> </add-edit>
    <b-card no-body>
      <b-card-header class="w-100">
        <b-row class="w-100">         
          <b-col lg="3" class="mb-lg-0 mb-1">
            <b-form-group label="Codigo" label-for="correo">
              <b-form-input
                @input="filter"
                v-model="code"
                id="code"
                placeholder="Codigo"
                autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col lg="3" class="mb-lg-0 mb-1">
            <b-form-group label="Descripcion" label-for="correo">
              <b-form-input
                @input="filter"
                v-model="description"
                id="description"
                placeholder="Descripcion"
                autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="3"
            class="mb-md-0 mb-2 d-flex justify-content-center justify-content-md-start align-items-center"
          >  
          <b-button variant="secondary" class="mt-05" style="margin-right: 7px;" @click.prevent="clean">
              <span class="text-nowrap"
                ><feather-icon icon="TrashIcon" size="15" /> Ver Todos</span
              >
            </b-button>
            <b-button variant="primary" class="mt-05" @click="addCate">
              <span class="text-nowrap"
                ><feather-icon icon="PlusCircleIcon" size="15" /> Agregar</span
              >
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable*/
import Vue from 'vue'
import { BootstrapVue, VBToggle } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import addEdit from './add-edit.vue'
Vue.use(BootstrapVue)
export default {
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  data() {
    return {
      isAdd: false,
      isUpdate: false,
      see_more: false,
      description:"",
      code:"",
      project_id: JSON.parse(localStorage.getItem('project_id')),
      proyectos: [],
      showEntrie: 10,
      arrayFilters: [],
      userData: JSON.parse(localStorage.getItem('userData')),
      user_role: JSON.parse(localStorage.getItem('userData')).role_user[0].role_restriction,
    }
  },
  components: {
    vSelect,
    addEdit
  },
  mounted() {
    this.filter()
    
  },
  methods: {
    
    clickSeeMore() {
      this.see_more = !this.see_more
    },
    addCate() {
      this.isAdd = true
      this.$refs.cateAdd.setData({})
    },
    filter() {
      var arrayFilter = []
      if (this.code != '' && this.code != null) {
        arrayFilter.push({
          keyContains: 'code',
          key: 'contains',
          value: this.code
        })
      }
      if (this.description != '' && this.description != null) {
        arrayFilter.push({
          keyContains: 'description',
          key: 'contains',
          value: this.description
        })
      }
      console.log('arrayFilter', arrayFilter)
      this.$parent.$parent.arrayFilters = arrayFilter
      this.$parent.$parent.getData()
    },
    clean() {
     
      var arrayFilter = []    

      this.$parent.$parent.arrayFilters = arrayFilter
      this.$parent.$parent.getData()
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.body_card {
  display: block;
  transition: 0.3s;
}
.mt-05{
  margin-top: 0.5rem;
}
</style>
